export default {
    path: '/dxalk',
    name: 'dxalk',
    meta: {
        module: 'dxalk',
        title: '光明区智能执法平台',
        roles: ['admin', 'group', 'user'],
        icon: 'yingyongpingtaiguanli'
    },
    children: [
        {
            path: '/dxalk/dynamic',
            name: 'dynamic',
            meta: {
                module: 'dxalk',
                title: '光明区智能执法平台',
                roles: ['admin', 'group', 'user'],
                icon: ''
            },
            children: [
              {
                  path: '/dxalk/dynamic/entryManage',
                  name: 'entryManage',
                  meta: {
                      module: 'dynamic',
                      title: '光明区智能执法平台',
                      roles: ['admin', 'group', 'user'],
                      icon: ''
                  }
              },
              {
                    path: '/dxalk/dynamic/entryEdit',
                    name: 'entryEdit',
                    meta: {
                        module: 'dynamic',
                        title: '光明区智能执法平台',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
              },
              {
                path: '/dxalk/dynamic/commentManage',
                name: 'commentManage',
                meta: {
                  module: 'dynamic',
                  title: '光明区智能执法平台',
                  roles: ['admin', 'group', 'user'],
                  icon: ''
                }
              },
              {
                path: '/dxalk/dynamic/checkManage',
                name: 'checkManage',
                meta: {
                  module: 'dynamic',
                  title: '光明区智能执法平台',
                  roles: ['admin', 'group', 'user'],
                  icon: ''
                }
              },
              {
                path: '/dxalk/dynamic/checkManage/detail',
                name: 'detailId',
                meta: {
                  module: 'dynamic',
                  title: '光明区智能执法平台',
                  roles: ['admin', 'group', 'user'],
                  icon: ''
                }
              }
            ]
        },
        {
          path: '/dxalk/case',
          meta: {
              module: 'dxalk',
              title: '',
              roles: ['admin', 'group', 'user'],
              icon: ''
          },
          children:[
            {
              path: '/dxalk/case/enter',
              meta: {
                  module: 'case',
                  title: '案例录入',
                  roles: ['admin', 'group', 'user'],
                  icon: ''
              }
            },
            {
              path: '/dxalk/case/publish',
              meta: {
                  module: 'case',
                  title: '案例发布',
                  roles: ['admin', 'group', 'user'],
                  icon: ''
              }
            },
            {
              path: '/dxalk/case/expimp',
              meta: {
                  module: 'case',
                  title: '导入导出',
                  roles: ['admin', 'group', 'user'],
                  icon: ''
              }
            }
          ]
        },
        {
          path: '/dxalk/fy-case',
          meta: {
              module: 'dxalk',
              title: '',
              roles: ['admin', 'group', 'user'],
              icon: ''
          },
          children:[
            {
              path: '/dxalk/fy-case/enter',
              meta: {
                  module: 'case',
                  title: '案例录入',
                  roles: ['admin', 'group', 'user'],
                  icon: ''
              }
            },
            {
              path: '/dxalk/fy-case/publish',
              meta: {
                  module: 'case',
                  title: '案例发布',
                  roles: ['admin', 'group', 'user'],
                  icon: ''
              }
            },
            {
              path: '/dxalk/fy-case/expimp',
              meta: {
                  module: 'case',
                  title: '导入导出',
                  roles: ['admin', 'group', 'user'],
                  icon: ''
              }
            }
          ]
        }
    ]
}
